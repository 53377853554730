<template>
  <div class="schema-builder-canvas-metric-card-placeholder">
    <div class="schema-builder-canvas-metric-card-placeholder-items">
      <div />
      <div />
      <div />
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.schema-builder-canvas-metric-card-placeholder {
  display: flex;
  height: 48px;
  width: 100%;
  padding: 12px var(--Unit-4, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--25, 10px);

  border-radius: var(--Radius-Radius-2, 8px);
  opacity: 0.5;
  background: rgba(13, 22, 22, 0.10);

  .schema-builder-canvas-metric-card-placeholder-items {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    align-self: stretch;

    div {
      width: 30%;
      height: var(--2, 8px);
      border-radius: var(--Radius-Radius-Full, 100000px);
      background: rgba(13, 22, 22, 0.10);
    }
  }
}
</style>
