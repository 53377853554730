import Api from '@/store/helpers/api';
import { logger } from '@/store/logger';
import { computed, Ref, ref } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import { FileUpload } from '@/components/general/FileUpload/types';
import constants from '@/components/general/FileUpload/constants';

export default () => {
  const fileUploads: Ref<FileUpload[]> = ref([]);
  const failedUploads: Ref<FileUpload[]> = ref([]);
  const store = useStore();
  const idToken = computed(() => store.getters['authenticate/idToken']);
  const client = (new Api(process.env, idToken.value));

  function addFailedUpload(fileUpload: FileUpload) {
    failedUploads.value = [...failedUploads.value, fileUpload];
  }

  function updateFileUploads(newFileUploads: FileUpload[]) {
    fileUploads.value = newFileUploads;
  }

  async function uploadFileToS3(file: File, id?: string): Promise<FileUpload> {
    const fileUpload: FileUpload = {
      id: id ?? uuid(),
      name: file.name,
      file,
      error: null,
      uploadPostData: null,
      downloadUrl: null,
    };

    // Generate a pre-signed upload url
    let uploadPostData: any;
    try {
      uploadPostData = await client.post('upload-reference-document', { documentFileType: constants.DOCUMENT_FILE_TYPES[file.type] });
      fileUpload.uploadPostData = uploadPostData;
    } catch (e) {
      logger.debug('Failed to generate a pre-signed upload url', e);
      fileUpload.error = 'Failed to generate a pre-signed upload url';
      addFailedUpload(fileUpload);
      return fileUpload;
    }

    // Upload the file to S3
    try {
      const formData = new FormData();
      Object.entries(uploadPostData.fields).forEach(([key, value]) => {
        formData.append(key, value as any);
      });
      formData.append('file', file);
      await axios.post(uploadPostData.url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    } catch (e) {
      logger.debug('Failed to upload document to S3', e);
      fileUpload.error = 'Failed to upload document to S3';
      addFailedUpload(fileUpload);
      return fileUpload;
    }

    // Generate download url
    try {
      const { url: downloadUrl } = await client.post('download-reference-document', { key: uploadPostData.fields.key, documenFileType: 'PNG' });
      fileUpload.downloadUrl = downloadUrl;
    } catch (e) {
      logger.debug('Failed to generate download url');
      fileUpload.error = 'Failed to generate download url';
      addFailedUpload(fileUpload);
      return fileUpload;
    }

    // addSuccessfulUpload(fileUpload);

    return fileUpload;
  }

  async function retryFailedFileUpload(id: string): Promise<FileUpload> {
    const fileUpload = failedUploads.value.find((f) => f.id === id);
    if (!fileUpload) {
      throw Error('File upload not found');
    }
    try {
      const upload = await uploadFileToS3(fileUpload.file, fileUpload.id);
      if (upload.error) {
        throw Error();
      }
    } catch (e) {
      logger.debug('Upload re-try failed');
      throw Error('Failed to re-upload file');
    }

    return fileUpload;
  }

  function deleteFileUpload(id: string) {
    fileUploads.value = fileUploads.value.filter((u) => u.id !== id);
  }

  return {
    fileUploads,
    uploadFileToS3,
    updateFileUploads,
    retryFailedFileUpload,
    deleteFileUpload,
  };
};
