/* eslint-disable import/prefer-default-export */
import { getAllMetrics } from '@/store/schemaBuilder/helpers';
import { SchemaMetric, SchemaMetricGroup } from '@/store/schemaBuilder/types';
import { z } from 'zod';

// Helper function to validate the reporting date metric
function validateReportingDateMetric(
  group: SchemaMetricGroup,
  allGroupMetrics: SchemaMetric[],
  ctx: z.RefinementCtx,
) {
  const reportingDateMetric = allGroupMetrics.find((metric) => metric.fin === 'reporting_date');
  if (group.isRepeatingGroup && reportingDateMetric) {
    const subGroup = group.subGroups.find(
      (sG) => sG.metrics.find(
        (metric) => metric.fin === reportingDateMetric.fin,
      ),
    );
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      params: {
        metric: reportingDateMetric,
        location: {
          metricGroupName: group.displayName,
          subGroupName: subGroup?.displayName,
          metricName: reportingDateMetric.displayName,
        },
      },
      message: 'Reporting date metric cannot exist within a multi-value group.',
    });
  }
}

// Ensure that the reporting date metric exists at least once across all groups
function validateReportingDatePresence(allGroupMetrics: SchemaMetric[], ctx: z.RefinementCtx) {
  const reportingDateMetric = allGroupMetrics.find((metric) => metric.fin === 'reporting_date');

  if (!reportingDateMetric) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'A reporting date metric must exist in at least one group.',
    });
  }
}

// Helper function to validate subgroups of the schema metric group
function validateSubGroups(
  group: SchemaMetricGroup,
  groupIndex: number,
  ctx: z.RefinementCtx,
) {
  group.subGroups.forEach((subGroup, subGroupIndex) => {
    // Check if sub-group contains at least one metric
    if (subGroup.metrics.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        params: {
          metricGroupIndex: groupIndex,
          subGroupIndex,
          location: {
            metricGroupName: group.displayName,
            subGroupName: subGroup.displayName,
          },
        },
        message: 'View must contain at least 1 metric.',
      });
    }

    // If time period filter is enabled, all metrics must have time periods
    if (subGroup.enableTimePeriodFilter) {
      const metricsMissingTimePeriod = subGroup.metrics.some((metric) => !metric.timePeriod);
      if (metricsMissingTimePeriod) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          params: {
            metricGroupIndex: groupIndex,
            subGroupIndex,
            location: {
              metricGroupName: group.displayName,
              subGroupName: subGroup.displayName,
            },
          },
          message: 'All metrics must have an associated time period.',
        });
      }
    }
  });
}

// Helper function to validate Entity Link metrics within the group
function validateEntityLinkMetrics(
  groups: SchemaMetricGroup[],
  group: SchemaMetricGroup,
  allGroupMetrics: SchemaMetric[],
  ctx: z.RefinementCtx,
) {
  allGroupMetrics.forEach((metric) => {
    if (metric.type === 'ENTITY_LINK' && metric.associatedEntityType) {
      const { associatedEntityType } = metric;

      // Check if any repeating group has the associated entity type metric
      const linkedMetricGroup = groups.find(
        (g) => getAllMetrics([g]).some((m) => m.fin === associatedEntityType?.fin),
      );

      const isLinkValid = linkedMetricGroup && linkedMetricGroup.isRepeatingGroup;

      if (!isLinkValid) {
        const subGroup = group.subGroups.find(
          (sG) => sG.metrics.find(
            (m) => m.fin === metric.fin,
          ),
        );
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          params: {
            metric,
            location: {
              metricGroupName: group.displayName,
              subGroupName: subGroup?.displayName,
              metricName: metric.displayName,
            },
          },
          message: `The '${associatedEntityType?.name}' metric must be present in at least one multi-value group for this entity link metric to be valid.`,
        });
      }
    }
  });
}

// Main function to validate schema metric groups
export function validateSchemaMetricGroups(groups: SchemaMetricGroup[], ctx: z.RefinementCtx) {
  const allMetrics = getAllMetrics(groups); // Gather all metrics once
  validateReportingDatePresence(allMetrics, ctx);
  groups.forEach((group, groupIndex) => {
    const allGroupMetrics = getAllMetrics([group]); // Gather all metrics once
    validateReportingDateMetric(group, allGroupMetrics, ctx);
    validateSubGroups(group, groupIndex, ctx);
    validateEntityLinkMetrics(groups, group, allGroupMetrics, ctx);
  });
}
