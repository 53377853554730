import { z } from 'zod';
import { validateSchemaMetricGroups } from './schemaFormValidators';

const schemaFormValidationSchema = z.object({
  name: z.string().min(1, 'Schema name required'),
  groups: z.array(z.object({
    displayName: z.string().min(1, 'Metric group name required'), // Add unique validation
    isRepeatingGroup: z.boolean(),
    calculatedTotals: z.boolean(),
    generalMetrics: z.array(z.any()),
    subGroups: z.array(z.object({
      metrics: z.array(z.any()),
      displayName: z.string().min(1, 'Required'),
      enableTimePeriodFilter: z.boolean(),
    })),
  }))
    .superRefine((groups, ctx) => {
      validateSchemaMetricGroups(groups, ctx);
    }),
});

export default schemaFormValidationSchema;
